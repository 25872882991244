<template>
  <v-container fluid>
    <v-card v-if="error">

      <v-card-title>
        Permission Denied
      </v-card-title>

      <v-card-text>
        {{ error }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['error', 'requestedUrl'],

  methods: {
    ...mapActions('Auth', ['signOut']),
  },

  created() {

    this.signOut()

    .then(() => {

      if (

        // Not already on sign-in component
        this.$router.currentRoute.name != 'sign-in'

      ) {
        this.$router.push({
          name: 'sign-in'
        })
      }
    })
  }
}
</script>
